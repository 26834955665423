.container-section3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-image: url("/public/Group 39908.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: #010102;
  padding-bottom: 39px;
}

.div-titles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
.div-paragrafo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
}

.container-text {
  margin-top: 24.5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.title-1 {
  font-family: Outfit;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.68px;
  letter-spacing: -0.02em;
  text-align: center;
  min-width: 274px;
  width: 100%;
}

.title-2 {
  font-family: Outfit;
  font-size: 36.99px;
  font-weight: 700;
  line-height: 36.99px;
  letter-spacing: -0.02em;
  text-align: center;
  min-width: 293px;
  width: 100%;
}

.title-3 {
  font-family: Outfit;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.68px;
  letter-spacing: -0.02em;
  text-align: center;
  min-width: 303px;
  width: 100%;
}

.paragrafo {
  width: 100%;
  font-family: Outfit;
  font-size: 1em;
  font-weight: 400;
  line-height: 18.9px;
  letter-spacing: -0.02em;
  text-align: center;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
  color: white;
  margin-top: 29px;
  letter-spacing: 0dvw;
}

span {
  color: rgba(0, 209, 255, 1);
}

.container-cards-valores {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 9.97px;
  padding-left: 20px;
  padding-right: 20px;
}

.div-valor1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  padding: 30px;
  height: 133.03px;
  min-width: 217.57px;
  border-radius: 20px;
  background-image: url("/public/card1.png");
  background-size: cover;
  background-position: center;
  background-color: black;
  background-repeat: no-repeat;
  color: white;
}

.div-valor1 > h2 {
  font-family: Outfit;
  font-size: 18.03px;
  font-weight: 800;
  line-height: 22.72px;
  letter-spacing: 0.12em;
  text-align: center;
}

.div-valor2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 133.03px;
  padding: 30px;
  min-width: 217.57px;
  border-radius: 20px;
  background-image: url("/public/card-2.png");
  background-size: cover;
  background-position: center;
  background-color: black;
  background-repeat: no-repeat;
  color: white;
}

.div-valor2 > h2 {
  font-family: Outfit;
  font-size: 18.03px;
  font-weight: 800;
  line-height: 22.72px;
  letter-spacing: 0.12em;
  text-align: center;
}

.div-valor3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 133.03px;
  padding: 30px;
  min-width: 217.57px;
  border-radius: 20px;
  background-image: url("/public/card-3.png");
  background-size: cover;
  background-position: center;
  background-color: black;
  background-repeat: no-repeat;
  color: white;
}

.div-valor3 > h2 {
  font-family: Outfit;
  font-size: 18.03px;
  font-weight: 800;
  line-height: 22.72px;
  letter-spacing: 0.12em;
  text-align: center;
}

@media (min-width: 820px) and (max-width: 1024px) {
  .container-section3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-image: url("/public/bg-section2-tablet.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: #010102;
    padding-bottom: 39px;
  }

  .container-text {
    margin-top: 29px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .title-1 {
    width: 396px;
    height: 33px;
    font-family: Outfit;
    font-size: 26px;
    font-weight: 400;
    line-height: 32.76px;
    letter-spacing: -0.02em;
    text-align: left;
  }

  .title-2 {
    font-family: Outfit;
    font-size: 68.42px;
    font-weight: 700;
    line-height: 68.42px;
    letter-spacing: -0.02em;
    text-align: center;
  }

  .title-3 {
    font-family: Outfit;
    font-size: 26px;
    font-weight: 400;
    line-height: 32.76px;
    letter-spacing: -0.02em;
    text-align: center;
  }

  .subtitle {
    margin-top: 4px;
  }

  .div-paragrafo {
    width: 100%;
    max-width: 100%;
    padding-left: 65px;
    padding-right: 65px;
    margin-bottom: 33px;
  }

  .paragrafo {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: Outfit;
    font-family: Outfit;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 22.68px;
    letter-spacing: -2%;
    text-align: center;
    color: white;
    margin-top: 29px;
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
  }

  span {
    color: rgba(0, 209, 255, 1);
  }

  .container-cards-valores {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .div-valor {
    width: 217.57px;
    height: 133.03px;
    border-radius: 12.43px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 1025px) {
  .container-section3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-image: url("/public/bg-section3-full.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    background-color: #010102;
    padding-bottom: 39px;
  }

  .container-cards-valores {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }

  .container-text {
    margin-top: 29px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1109px;
  }

  .div-titles {
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
  }

  .title-1 {
    font-family: Outfit;
    font-size: 36px;
    font-weight: 400;
    line-height: 45.36px;
    letter-spacing: -0.02em;
    text-align: left;
    width: 290px;
    left: 405px;
  }

  .title-2 {
    font-family: Outfit;
    font-size: 77.89px;
    font-weight: 700;
    line-height: 77.89px;
    letter-spacing: -0.02em;
    text-align: left;
    width: 369px;
    height: 156px;
  }

  .title-3 {
    font-family: Outfit;
    font-size: 36px;
    font-weight: 400;
    line-height: 45.36px;
    letter-spacing: -0.02em;
    text-align: left;
    width: 399px;
    height: 90px;
    top: 898px;
    left: 405px;
  }

  .div-paragrafo {
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 20px;
  }

  .paragrafo {
    display: flex;
    flex-direction: column !important;
    text-align: left;
    font-family: Outfit;
    font-size: 22px !important;
    font-weight: 400;
    line-height: 27.72px;
    letter-spacing: -0.02em;
    width: 100%;
  }

  .div-valor1 > h2 {
    font-family: Outfit;
    font-size: 24.28px;
    font-weight: 800;
    line-height: 30.59px;
    letter-spacing: 0.12em;
    text-align: center;
  }

  .div-valor1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 350px;
    height: 214px;
  }

  .div-valor2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 350px;
    height: 214px;
  }
  .div-valor3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 350px;
    height: 214px;
  }

  .div-valor2 > h2 {
    font-family: Outfit;
    font-size: 24.28px;
    font-weight: 800;
    line-height: 30.59px;
    letter-spacing: 0.12em;
    text-align: center;
  }

  .div-valor3 > h2 {
    font-family: Outfit;
    font-size: 24.28px;
    font-weight: 800;
    line-height: 30.59px;
    letter-spacing: 0.12em;
    text-align: center;
  }
}
