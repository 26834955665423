.container-cards {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #010c18;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  padding: 20px;
  gap: 20px;
}

.title-cards {
  position: relative;
  font-family: Outfit;
  font-size: 18px !important;
  font-weight: 700;
  line-height: 22.68px;
  letter-spacing: -0.02em;
  text-align: center;
  margin-top: 38px;
}

.div-image-fluxo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  margin-top: 20px;
  width: 90%;
  height: 460px;
  background-image: url("/public/fluxo-etapas.png");
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
}

.div-image-fluxo2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  margin-top: 25px;
  width: 90%;
  height: 430px;
  background-image: url("/public/etapas.png");
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  margin-bottom: 30px;
}

@media (min-width: 820px) {
  .div-image-fluxo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 30px;
    width: 830px;
    max-height: 230px;
    background-image: url("/public/fluxo-etapas-tablet.png");
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;
  }

  .title-cards {
    position: relative;
    font-family: Outfit;
    font-size: 26px !important;
    font-weight: 700;
    line-height: 26.68px;
    letter-spacing: -0.02em;
    text-align: center;
    margin-top: 38px;
  }

  .div-image-fluxo2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 0px;
    width: 830px;
    max-width: 1190px;
    max-height: 300px;
    background-image: url("/public/etapas-tablet.png");
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;
  }
}

@media (min-width: 1920px) {
  .div-image-fluxo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    margin-top: 40px;
    width: 100%;
    max-width: 1190px;
    height: 300px;
    background-image: url("/public/fluxo-etapas-full.png");
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;
  }
  .div-image-fluxo2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    margin-top: 30px;
    width: 100%;
    max-width: 1190px;
    background-image: url("/public/etapa-full.png");
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;
  }

  .title-cards {
    position: relative;
    font-family: Outfit;
    font-size: 36px !important;
    font-weight: 700;
    line-height: 26.68px;
    letter-spacing: -0.02em;
    text-align: center;
    margin-top: 38px;
  }
}
