.section4-diversidade {
  width: 100%;
  background-color: #0d0d0d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.container-diversidade {
  width: 80%;
  border-radius: 30px;
  gap: 20px;
  border: 1px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid rgba(0, 231, 255, 1);
}

.container--diversidade__text {
  color: #fff;
  text-align: center;
}

.diversidade-title-1 {
  font-family: Outfit;
  font-size: 18px;
  font-weight: 600;
  line-height: 22.68px;
  text-align: center;
  color: #fff;
}

.container__img {
  width: 100%;
  height: 20vh;
  background-image: url("/public/friend.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border-radius: 20px;
}

@media (min-width: 820px) {
  .diversidade-title-1 {
    font-family: Outfit;
    font-size: 26px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -2%;
    text-align: left;
  }

  .container-text-diversidade,
  .container--diversidade__text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 10px;
    padding-right: 10px;
  }

  .container-imagem-diversidade {
    background-image: url("/public/friend-tablet.png");
    max-width: 417px;
    width: 100%;
    height: 400px;
    border-radius: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .container-diversidade {
    width: 100%;
    max-width: 1110px;
    border-radius: 30px;
    gap: 5px;
    border: 1px;
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid rgba(0, 231, 255, 1);
  }

  .div-paragrafo-1 {
    display: flex;
    flex-direction: column;
    font-family: Outfit;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 20.72px;
    letter-spacing: -0.02em;
    text-align: left;
    width: 100%;
    margin-top: 20px;
  }
}

@media (min-width: 1024px) {
  .container-imagem-diversidade {
    background-image: url("/public/friend.png");
    max-width: 417px;
    width: 100%;
    height: 400px;
    border-radius: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}
