.section--beneficios {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 33px;
  padding-right: 33px;
  padding-top: 78px;
  gap: 20px;
  background-color: #0d0d0d;
}

.div-text__h2 {
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 34px;
}

.section--beneficios__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 30px;
}

.div-text__p {
  font-size: 15px;
  color: white;
}

.div-text__span {
  font-size: 18px;
}

.section--beneficios__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 330px;
  height: 47.49px !important;
  border-radius: 50px;
  border: none;
  color: white;
  padding-left: 54px;
  padding-right: 54px;
  background: linear-gradient(to right, #4285f4, #fe2e36);
  margin-top: 47px;
  margin-bottom: 47px;
}

@media (min-width: 768px) {
  .section--beneficios {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .section--beneficios__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 30px;
  }
  .section--bebeficios__div-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 30px;
  }

  .div-text__h2 {
    margin-top: 102px;
    text-align: center;
    font-size: 36px;
    width: 100%;
    max-width: 900px;
    line-height: 30px;
  }

  .div-text__p {
    font-size: 18px !important;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .div-text__span {
    font-size: 22px !important;
  }
}

@media (min-width: 1024px) {
  .div-text__h2 {
    margin-top: 102px;
    text-align: center;
    font-size: 36px;
    width: 100%;
    max-width: 900px;
    line-height: 40px;
  }

  .div-text__p {
    font-size: 18px !important;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
  }
  .section--beneficios__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 410px !important;
    height: 47.49px !important;
    border-radius: 50px;
    border: none;
    color: white;
    padding-left: 54px;
    padding-right: 54px;
    background: linear-gradient(to right, #4285f4, #fe2e36);
    margin-top: 33px;
    margin-bottom: 47px;
    font-size: 22px;
  }

  .div-text__span {
    font-size: 30px !important;
  }
}
