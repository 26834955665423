.card--beneficios__div {
  width: 100%;
  max-width: 690px;
  min-height: 303.31px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  padding: 30px;
  gap: 7px;
}
.iconsCard {
  width: 20px !important;
  height: 20px !important;
}

.h2-title {
  width: 100%;
  font-size: 1rem;
  line-height: 20px;
  text-align: center;
}

.h2-title-2 {
  width: 100%;
  font-size: 1rem;
  color: #191919;
  line-height: 27px;
  text-align: center;
}

.card--beneficios__li {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  color: #2f2f2f !important;
  font-size: 11.94px;
  text-align: left;
  gap: 20px;
}

.card--Requisitos__div {
  width: 100%;
  max-width: 690px;
  min-height: 303.31px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #191919;
  border-radius: 20px;
  padding: 30px;
  gap: 7px;
}

.card--Requisitos__li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: #ffff;
  font-size: 11.94px;
  text-align: left;
  gap: 20px;
}

.card--Requisitos__title {
  color: white;
}

.card--beneficios__title {
  color: #191919;
  width: 50%;
}
@media (min-width: 768px) {
  .card--Requisitos__li {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    color: #ffff;
    font-size: 1.6rem;
    text-align: left;
    gap: 20px;
  }

  .card--beneficios__li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    color: #2f2f2f;
    font-size: 1.6rem;
    text-align: left;
    gap: 20px;
  }

  .card--beneficios__div {
    width: 100%;
    max-width: 540px;
    height: 100%;
    min-height: 303.31px;
    height: 559px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #fff;
    border-radius: 20px;
    padding: 30px;
    gap: 0px;
  }

  .card--Requisitos__div {
    width: 100%;
    max-width: 540px;
    min-height: 303.31px;
    height: 100%;
    height: 559px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #191919;
    border-radius: 20px;
    padding: 30px;
    gap: 7px;
  }

  .iconsCard {
    width: 20px !important;
    height: 20px !important;
  }

  .h2-title {
    width: 100%;
    font-size: 1.6rem;
    line-height: 20px;
    text-align: center;
  }

  .h2-title-2 {
    width: 100%;
    font-size: 1.6rem;
    color: #191919;
    line-height: 27px;
    text-align: center;
  }

  .card--Requisitos__title {
    color: white;
    font-size: 22px !important;
  }

  .card--beneficios__title {
    color: #191919;
    font-size: 22px !important;
  }
}
