.section--programa {
  background-image: url("/public/bg-section4-mobile.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 560px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-left: 33px;
  padding-right: 33px;
}

.section--programa__title {
  width: 100%;
}

.section--programa__title > h2 {
  font-family: Outfit;
  font-size: 18px;
  font-weight: bold;
  line-height: 22.68px;
  letter-spacing: -0.02em;
  text-align: center;
}

.section--programa__paragrafo {
  margin-top: 20px;
  width: 100%;
}

.section--programa__paragrafo > p {
  font-family: Outfit;
  font-size: 15px;
  font-weight: 400;
  line-height: 18.9px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #fff;
}

.section--programa > a > button {
  width: 155px !important;
  height: 25.83px !important;
  padding: 0px;
  gap: 5.74px;
  margin-top: 30px;
  border-radius: 28.7px;
  color: #ffff;
  margin-bottom: 62.7px;
  background: linear-gradient(90deg, #4285f4 0%, #00d1ff 100%);
}

@media (min-width: 820px) and (max-width: 1024px) {
  .section--programa {
    background-image: url("/public/bg-section4-tablet.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 405px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
    padding-left: 33px;
    padding-right: 33px;
  }
  .section--programa__title {
    width: 100%;
  }

  .section--programa__title > h2 {
    font-family: Outfit;
    font-size: 26px;
    font-weight: bold;
    line-height: 32.76px;
    text-align: center;
  }

  .section--programa__paragrafo {
    margin-top: 20px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  .section--programa__paragrafo > p {
    font-family: Outfit;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 22.68px;
    text-align: center;
  }

  .section--programa > a > button {
    width: 270px !important;
    height: 45px !important;
    padding: 0px;
    gap: 10px;
    border-radius: 50px;
    margin-top: 30px;
    opacity: 0px;
    background: linear-gradient(90deg, #4285f4 0%, #00d1ff 100%);
  }
}

@media (min-width: 1025px) {
  .section--programa {
    background-image: url("/public/bg-section4-full.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    gap: 20px;
  }

  .section--programa__title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 92px;
  }

  .section--programa__title > h2 {
    font-family: Outfit;
    font-size: 36px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -0.02em;
    text-align: center;
    width: 1109px;
  }
  .section--programa__paragrafo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
  }

  .section--programa__paragrafo > p {
    font-family: Outfit;
    font-size: 22px !important;
    font-weight: 400;
    line-height: 27.72px;
    letter-spacing: -0.02em;
    text-align: center;
    width: 954px;
  }

  .section--programa > a > button {
    width: 270px !important;
    height: 45px !important;
    margin-top: 30px;
    border-radius: 30px;
    background: linear-gradient(90deg, #4285f4 0%, #00d1ff 100%);
  }

  .a--buttonCta2 {
    max-width: 270px;
    width: 100%;
    height: 45px !important;
    border-radius: 50px;
    border: none;
    background: linear-gradient(to right, #4285f4, #00d1ff);
    color: #ffff;
    font-size: 26px;
    line-height: 15.91px;
    margin-top: 15px;
    text-align: center;
    padding: 15px;
    margin-bottom: 100px;
  }
  .a--buttonCta2:hover {
    font-weight: 600;
    background: linear-gradient(to right, #5f9bfc, #23d7ff);
    box-shadow: 0.2px 0.3px 5px #5f9bfc;
  }
}
