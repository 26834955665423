@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

.App {
  width: 100%;
  text-align: center;
  background-color: #1e1e1e;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

* {
  font-family: "Outfit";
}
