.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 360px;
  height: 100%;
}

.ui.styled.accordion {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  justify-items: center;
  gap: 20px;
  width: 80%;
  margin: auto; /* Centraliza o acordeão horizontalmente */
  padding: 10px;
}

.ui.styled.accordion .title {
  cursor: pointer;
  padding: 10px;
  background: #191919;
  border: 1px solid #262626;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  width: 100%;
  border-radius: 10px;
  /*   height: 10vh;
 */
}

.ui.styled.accordion .icon {
  transition: transform 0.3s ease;
  width: 30px !important;
  height: 30px !important;
}

.ui.styled.accordion .icon-active {
  transform: rotate(90deg); /* Rotaciona o ícone quando ativo */
}

.ui.styled.accordion .content {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 2%;
  border: 0px solid #ddd;
  text-align: left;
  overflow: hidden;
  max-height: 0;
  width: 100%;
  font-size: 18px;
  transition: max-height 0.3s ease-out;
  background: #191919; /* Adiciona um fundo consistente com o título */
}

.ui.styled.accordion .item.active .content {
  color: #fff;
  max-height: 100%; /* Ajuste conforme o conteúdo */
}

@media (min-width: 768px) {
  .ui.styled.accordion {
    grid-template-columns: repeat(2, 1fr); /* 2 colunas em desktop */
  }
}

@media (min-width: 1440px) {
  .ui.styled.accordion {
    grid-template-columns: repeat(3, 1fr); /* 2 colunas em desktop */
  }
}

@media (max-width: 767px) {
  .ui.styled.accordion {
    grid-template-columns: 1fr; /* 1 coluna em mobile */
  }
}
