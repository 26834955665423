.section--principal {
  width: 100%;
  height: 587px;
  padding-top: 106px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: #0c0c16;
  background-image: url("/public/bg-mobile-section1.png");
  background-size: inherit;
  background-repeat: no-repeat;
  background-position: right bottom;
}

.container--text {
  width: 50%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

h2,
.text-title-secundary {
  font-size: 34.41px;
  text-align: left;
  font-weight: 700;
  line-height: 43.36px;
  letter-spacing: -2%;
  font-family: "Outfit";
  color: white;
}

.text-title-secundary {
  padding-left: 5%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.subtitle {
  font-size: 18px;
  font-weight: 400;
  line-height: 22.68px;
  letter-spacing: -2%;
  color: white;
  margin-top: 10.63px;
}
.a--buttonCta2 {
  max-width: 155px;
  width: 100%;
  height: 25.83px !important;
  border-radius: 50px;
  border: none;
  background: linear-gradient(to right, #4285f4, #00d1ff);
  color: #ffff;
  font-size: 12.63px;
  line-height: 15.91px;
  margin-top: 15px;
  text-align: center;
  padding: 5px;
}
.a--buttonCta2:hover {
  font-weight: 600;
  background: linear-gradient(to right, #5f9bfc, #23d7ff);
  box-shadow: 0.2px 0.3px 5px #5f9bfc;
}

.text-title-secundary__2024 {
  color: white;
  margin-left: 20px;
}

@media (min-width: 500px) {
  .section--principal {
    width: 100%;
    height: 587px;
    padding-top: 106px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: #0c0c16;
    background-image: url("/public/bg-mobile-section1.png");
    background-size: inherit;
    background-repeat: no-repeat;
    background-position: right bottom;
  }
  h2,
  .text-title-secundary {
    font-size: 64.41px;
    text-align: left;
    font-weight: 700;
    line-height: 73.36px;
    letter-spacing: -2%;
    font-family: "Outfit";
    color: white;
  }

  .text-title-secundary {
    padding-left: 5%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .container--text {
    width: 100%;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .a--buttonCta2 {
    max-width: 155px;
    width: 100%;
    height: 25.83px !important;
    border-radius: 50px;
    border: none;
    background: linear-gradient(to right, #4285f4, #00d1ff);
    color: #ffff;
    font-size: 12.63px;
    line-height: 15.91px;
    margin-top: 15px;
    text-align: center;
    padding: 5px;
  }
  .a--buttonCta2:hover {
    font-weight: 600;
    background: linear-gradient(to right, #5f9bfc, #23d7ff);
    box-shadow: 0.2px 0.3px 5px #5f9bfc;
  }
}

@media (min-width: 680px) {
  .section--principal {
    align-items: center;
  }
  .container--text {
    width: 78%;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 0px;
    padding-left: 0px;
  }
  h2,
  .text-title-secundary {
    font-size: 54.41px;
    text-align: left;
    font-weight: 700;
    line-height: 73.36px;
    letter-spacing: -2%;
    font-family: "Outfit";
    color: white;
  }

  .text-title-secundary {
    padding-left: 5%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .subtitle {
    font-size: 22px;
    font-weight: 400;
    line-height: 22.68px;
    letter-spacing: -2%;
    color: white;
    margin-top: 10.63px;
  }
  .a--buttonCta2 {
    max-width: 205px;
    width: 100%;
    height: 75px;
    height: 25.83px !important;
    border-radius: 50px;
    border: none;
    background: linear-gradient(to right, #4285f4, #00d1ff);
    color: #ffff;
    font-size: 12.63px;
    line-height: 15.91px;
    margin-top: 15px;
    text-align: center;
    padding: 5px;
  }
  .a--buttonCta2:hover {
    font-weight: 600;
    background: linear-gradient(to right, #5f9bfc, #23d7ff);
    box-shadow: 0.2px 0.3px 5px #5f9bfc;
  }
}
@media (min-width: 820px) and (max-width: 1440px) {
  .section--principal {
    width: 100%;
    height: 469px;
    padding-top: 136px;
    padding-left: 10px;
    padding-right: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #0c0c16;
    background-image: url("/public/bg-tablet-section1.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right bottom;
  }

  .container--text {
    width: 60%;
    padding-left: 0px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 60px;
    gap: 5px;
  }

  h2,
  .text-title-secundary {
    width: 100%;
    font-size: 55.42px;
    text-align: left;
    font-weight: 700;
    line-height: 60.36px;
    letter-spacing: -0%;
    font-family: "Outfit";
    color: white;
  }

  .text-title-secundary {
    padding-left: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 22.68px;
    letter-spacing: -2%;
    color: white;
  }

  .a--buttonCta2 {
    max-width: 270px;
    width: 100%;
    height: 45px !important;
    border-radius: 50px;
    border: none;
    background: linear-gradient(to right, #4285f4, #00d1ff);
    color: #ffff;
    font-size: 26px;
    line-height: 15.91px;
    margin-top: 15px;
    text-align: center;
    padding: 15px;
  }
  .a--buttonCta2:hover {
    font-weight: 600;
    background: linear-gradient(to right, #5f9bfc, #23d7ff);
    box-shadow: 0.2px 0.3px 5px #5f9bfc;
  }

  .subtitle {
    font-size: 26px !important;
    margin-top: 37.2px !important;
  }
}

@media (min-width: 1025px) {
  .section--principal {
    width: 100%;
    height: 569px;
    padding-top: 6px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #0c0c16;
    background-image: url("/public/bg-desktop-full.png");
    background-size: inherit;
    background-repeat: no-repeat;
    background-position: center bottom;
  }

  .container--text {
    margin-top: 150px;
    width: 80%;
    padding-left: 10px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 00px;
  }

  h2,
  .text-title-secundary {
    font-family: Outfit;
    font-size: 75.77px;
    font-weight: 700;
    line-height: 95.47px;
    letter-spacing: -0.02em;
    text-align: left;
    color: white;
  }

  .text-title-secundary {
    font-family: Outfit;
    font-size: 75.77px;
    font-weight: 700;
    line-height: 69.83px;
    letter-spacing: -0.02em;
    text-align: left;
    color: white;
    margin-bottom: 46px;
    padding-left: 60px;
  }

  .text-title-secundary__2024 {
    font-size: 90.68px;
    font-weight: 700;
    line-height: 69.83px;
    letter-spacing: -0.02em;
    color: white;
  }
  .a--buttonCta2 {
    max-width: 270px;
    width: 100%;
    height: 45px !important;
    border-radius: 50px;
    border: none;
    background: linear-gradient(to right, #4285f4, #00d1ff);
    color: #ffff;
    font-size: 26px;
    line-height: 15.91px;
    margin-top: 15px;
    text-align: center;
    padding: 15px;
  }
  .a--buttonCta2:hover {
    font-weight: 600;
    background: linear-gradient(to right, #5f9bfc, #23d7ff);
    box-shadow: 0.2px 0.3px 5px #5f9bfc;
  }

  .subtitle {
    font-family: Outfit;
    font-size: 30px !important;
    font-weight: 400;
    line-height: 37.8px;
    letter-spacing: -0.02em;
    text-align: left;
  }
}

@media (min-width: 1300px) {
  .section--principal {
    width: 100%;
    height: 569px;
    padding-top: 6px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0c0c16;
    background-image: url("/public/bg-desktop-full.png");
    background-size: inherit;
    background-repeat: no-repeat;
    background-position: center bottom;
  }

  .container--text {
    margin-top: 100px;
    width: 80%;
    padding-left: 10px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 100px;
  }
}

@media (min-width: 1500px) and (max-width: 1841px) {
  .container--text {
    margin-top: 100px;
    width: 80%;
    padding-left: 10px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 100px;
  }
}

@media (min-width: 1920px) {
  .section--principal {
    width: 100%;
    height: 569px;
    padding-top: 6px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0c0c16;
    background-image: url("/public/bg-desktop-full.png");
    background-size: inherit;
    background-repeat: no-repeat;
    background-position: center bottom;
  }

  .container--text {
    margin-top: 100px;
    width: 60%;
    padding-left: 10px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 200px;
  }
}
