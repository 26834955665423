@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

#header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 89px;
  position: fixed;
  z-index: 1000;
  transition: background-color 0.3s ease;
  padding: 20px;
}

.div--menuMobile {
  background-color: chocolate;
}

.container-menu {
  display: flex;
  width: 100%;
  gap: 5px;
}
.buttonCta {
  max-width: 270px !important;
  width: 100%;
  height: 45px !important;
  border-radius: 50px;
  border: none;
  padding: 8px;
  background: linear-gradient(to right, #4285f4, #00d1ff);
  color: #ffff;
}
.link-cta {
  width: 100%;
}

.buttonCtaMobile {
  width: 270px !important;
  height: 45px !important;
  border-radius: 50px;
  border: none;
  padding: 8px;
  background: linear-gradient(to right, #4285f4, #00d1ff);
  color: #ffff;
  font-size: 18px !important;
}
.buttonCta:hover {
  font-weight: 600;
  background: linear-gradient(to right, #5f9bfc, #23d7ff);
  box-shadow: 0.2px 0.3px 5px #5f9bfc;
}

.menu-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed;
  top: 0px;
  font-size: 22px;
  right: 0;
  padding-left: 30px;
  padding-top: 30px;
  width: 100vw;
  height: 100vh;
  text-align: left;
  transition: transform 0.3s ease-in-out;
  background-color: #1e1e1e;
  color: white;
  gap: 40px;
}

.menu-item {
  background: transparent;
  cursor: pointer;
}

a {
  cursor: pointer;
}

.link-cta {
  text-decoration: none;
}

@media (min-width: 768px) {
  .buttonCta {
    max-width: 170px;
  }
}

*,
p {
  font-size: 1em;
  font-family: "Outfit";
}

@media (min-width: 820px) and (max-width: 1512px) {
  #header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 89px;
    position: fixed;
    z-index: 1000;
    transition: background-color 0.3s ease;
  }
  p {
    font-size: 13px !important;
    font-family: "Outfit";
    display: flex;
    flex-wrap: nowrap;
  }
}
