.section--areaPrograma {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-image: url("/public/bg-accordeon.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  padding-bottom: 50px;
}

.section--areaPograma__div {
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section--areaPrograma__h2 {
  font-size: 18px !important;
  font-weight: 700;
  color: #fff;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 34px;
}

@media (min-width: 820px) {
  .section--areaPrograma__h2 {
    font-size: 26px !important;
    font-weight: 700;
    color: #fff;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 43px;
  }
}

@media (min-width: 1920px) {
  .section--areaPrograma__h2 {
    font-size: 36px !important;
    font-weight: 700;
    color: #fff;
    text-align: center;
    margin-bottom: 34px;
    margin-top: 54px;
  }
}
