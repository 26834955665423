footer {
  width: 100%;
  height: 212px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #010c18;
  color: white;
  gap: 30px;
}

.div-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.div--logo___footer {
  max-width: 150px;
}

@media (min-width: 820px) {
  footer {
  }

  .div-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 80%;
  }
}
